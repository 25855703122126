<script setup lang="ts">
// vue
import { computed } from 'vue'

/**
 * props
 * ==================================================================
 */
interface Props {
  show?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  show: false
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['toggle-refresh-snackbar'])

/**
 * computed
 * ==================================================================
 */
const toggleSnackbar = computed({
  get () {
    return props.show
  },
  set (val: boolean) {
    emit('toggle-refresh-snackbar', val)
  }
})

/**
 * methods
 * ==================================================================
 */
function refresh () {
  window.location.reload()
}
</script>

<template>
  <v-snackbar
    v-model="toggleSnackbar"
    :absolute="false"
    color="navy"
    location="bottom right"
    timeout="-1"
  >
    <v-row
      align="center"
      no-gutters
    >
      <div class="pa-2">
        A new version is available. Refresh to update!
      </div>
      <v-btn
        variant="outlined"
        class="mx-2"
        @click="refresh()"
      >
        Refresh
      </v-btn>
      <v-icon @click="toggleSnackbar = false">
        mdi-close
      </v-icon>
    </v-row>
  </v-snackbar>
</template>
